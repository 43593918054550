// import { Link } from 'react-router-dom'

// import { IconWhatsapp } from '../../utils/icons/whatsapp'
// import { IconBxlTelegram, IconTelegram } from '../../utils/icons/telegram'

// import './footer.css'

// export const Footer = () =>{
//     return(
//         <div className='footer'>
//             <div className='footer1'>
//                  <p><Link to='/'>Главная</Link></p> 
//                  <p><Link to='germetizacya'>Антикоррозионные работы</Link></p>
//                  <p><Link to='shlifovka'>Лакокрасочные работы</Link></p>
//                  <p><Link to='pokraska'>Кровельные работы</Link></p>
//             </div> 
//             <div className='footer2'>
//                 <p><Link to='peskostruy'>Изоляционные работы</Link></p>
//                 <p><Link to='malyar'>Пескоструйная очистка</Link></p>
//                 <p><Link to='photo'>О Нас</Link></p>
//             </div>
//             <div className='footer3'>
//                 <ul className='adressText'>
//                     <li>Адрес: 614000 Пермский край город Пермь </li>
//                     <li>ул. Монастырская 14 офис 351</li>
//                 </ul>
//                 <ul className='telephoneLink'>
//                     <li><a href="tel:+7-922-075-74-44">+7 (922) 075-74-44</a></li>
//                 </ul>
//                 <ul className='appLink'>
//                     <li><Link to="https://wa.me/79225777444"><IconWhatsapp/></Link></li>
//                     <li><Link to='https://t.me/+79225777444'><IconBxlTelegram/></Link></li>
//                 </ul>
//             </div>
//         </div>
//     )
// }

import { Link } from 'react-router-dom'

import { IconWhatsapp } from '../../utils/icons/whatsapp'
import { IconBxlTelegram, IconTelegram } from '../../utils/icons/telegram'

import './footer.css'

export const Footer = () =>{
    return(
        <div className='footer'>
            <div className='footer1'>
                <ul>
                    <li><Link to='/'>Главная</Link></li>
                    <li><Link to='photo'>О Нас</Link></li>
                    <li><Link to='germetizacya'>Антикоррозионные и лакокрасочные работы</Link></li>
                    <li><Link to='pokraska'>Кровельные работы</Link></li>
                </ul>
            </div> 
            <div className='footer2'>
                <ul>
                    <li><Link to='peskostruy'>Изоляционные работы</Link></li>
                    <li><Link to='malyar'>Пескоструйная очистка</Link></li>
                    <li><Link to='contact'>Контакты</Link></li>
                </ul>
            </div>
            <div className='footer3'>
                <ul className='adressText'>
                    <li>Адрес: 614000 Пермский край город Пермь </li>
                    <li>ул. Монастырская 14 офис 351</li>
                </ul>
                <ul className='telephoneLink'>
                    <li><a href="tel:+7-922-075-74-44">+7 (922) 075-74-44</a></li>
                </ul>
                <ul className='appLink'>
                    <Link to="https://wa.me/79225777444"><li><IconWhatsapp/></li></Link>
                    <Link to='https://t.me/+79225777444'><li><IconBxlTelegram/></li></Link>
                </ul>
            </div>
        </div>
    )
}



