import { useState } from 'react'

import { MenuPagesHeader4 } from '../../components/menuPagesHeader4/menuPagesHeader4'
import { IconLeft } from '../../utils/icons/left'
import { IconRight } from '../../utils/icons/right'
import img1 from "../../utils/img/krovlya/img1.jpg"
import img2 from "../../utils/img/krovlya/img2.jpg"
import img3 from "../../utils/img/krovlya/img3.jpg"
import img4 from "../../utils/img/krovlya/img4.jpg"
import img5 from "../../utils/img/krovlya/img5.jpg"

import './pokraska.css'

export const Pokraska = () => {
    const videoPath10 = `${process.env.PUBLIC_URL}/videos/video10.mp4`
    const videoPath11 = `${process.env.PUBLIC_URL}/videos/video11.mp4`
    const videoPath12 = `${process.env.PUBLIC_URL}/videos/video12.mp4`
    const videoPath13 = `${process.env.PUBLIC_URL}/videos/video13.mp4`
    const [currenrIndex, setCurrentIndex] = useState(0)
    const shipArray = [img1,img2,img3,img4,img5]
    const nextSlide = () =>{
        currenrIndex === shipArray.length-1 ? setCurrentIndex(0) : setCurrentIndex(currenrIndex + 1)
    }
    const prevSlide = () =>{
        currenrIndex === 0 ? setCurrentIndex(shipArray.length-1) : setCurrentIndex(currenrIndex - 1)
    }
    return(
        <div>
            {/* <MenuPagesHeader4/> */}
            <div className='pokraskaContainer'>
                <div className='pokraskaHeader'>
                    <span>Кровельные работы</span>
                </div>
                <div className='pokraskaText'>
                    <p>Строительные услуги, которые связаны с устройством крыш, утеплением крыш и ремонтом крыш, с реконструкцией кровли или ее обслуживанием в процессе эксплуатации.</p>                   
                </div>
                <div className='pokraskaHeader'>
                    <span>Примеры наших работ</span>
                </div>
                <div className='pokraskaphotoContainer'>
                    <div className='pokraskaphotoContainerSlide' >
                        <div className="pokraskaleft">
                            <button onClick={prevSlide}><IconLeft/></button>
                        </div>
                        <div className='pokraskaphotoContainerSlideImg'>
                            <img src={shipArray[currenrIndex]}/>
                        </div>
                        <div className="pokraskaright">
                            <button onClick={nextSlide}><IconRight/></button>
                        </div>
                        </div>
                </div>
               
                <div className='pokraskaVideoContainer'>
                    <video controls className='pokraskavideo'>
                        <source src={videoPath10} type="video/mp4" />
                    </video>
                    <video controls className='pokraskavideo'>
                        <source src={videoPath11} type="video/mp4" />
                    </video>
                    <video controls className='pokraskavideo'>
                        <source src={videoPath12} type="video/mp4" />
                    </video>
                    <video controls className='pokraskavideo'>
                        <source src={videoPath13} type="video/mp4" />
                    </video>
                </div>
            </div>
        </div>
    )
}