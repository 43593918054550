import { MenuPagesHeader7 } from '../../components/menuPagesHeader7/menuPagesHeader'
import './photo.css'
import img1 from "../../utils/img/izolacia/img1.jpg"
import img2 from "../../utils/img/izolacia/img2.jpg"
import img3 from "../../utils/img/izolacia/img3.jpg"
import img4 from "../../utils/img/izolacia/img4.jpg"
import img5 from "../../utils/img/izolacia/img5.jpg"
import img6 from "../../utils/img/izolacia/img6.jpg"
import img7 from "../../utils/img/krovlya/img1.jpg"
import img8 from "../../utils/img/krovlya/img2.jpg"
import img9 from "../../utils/img/krovlya/img3.jpg"
import img10 from "../../utils/img/krovlya/img4.jpg"
import img11 from "../../utils/img/krovlya/img5.jpg"
import img12 from "../../utils/img/lakokrasochniye/img1.jpg"
import img13 from "../../utils/img/lakokrasochniye/img2.jpg"
import img14 from "../../utils/img/lakokrasochniye/img3.jpg"
import img15 from "../../utils/img/peskostruy/img1.jpg"
import img16 from "../../utils/img/peskostruy/img4.jpg"
import img17 from "../../utils/img/peskostruy/img5.jpg"
import img18 from "../../utils/img/peskostruy/img6.jpg"
import img19 from "../../utils/img/peskostruy/img7.jpg"
import img20 from '../../utils/img/peskostruy/img8.jpg'
import img21 from "../../utils/img/peskostruy/img9.jpg"
import { useState } from 'react'
import { IconLeft } from '../../utils/icons/left'
import { IconRight } from '../../utils/icons/right'

export const Photo = () =>{
    const videoPath15 = `${process.env.PUBLIC_URL}/videos/video15.mp4`
    const [currenrIndex, setCurrentIndex] = useState(0)
    const shipArray = [videoPath15, img1,img2,img3,img4,img5,img6,img7,img8,img9,img10,img11,img12,img13,img14,img15,img16,img17,img18,img19,img20,img21]
    const nextSlide = () =>{
        currenrIndex === shipArray.length-1 ? setCurrentIndex(0) : setCurrentIndex(currenrIndex + 1)
    }
    const prevSlide = () =>{
        currenrIndex === 0 ? setCurrentIndex(shipArray.length-1) : setCurrentIndex(currenrIndex - 1)
    }
    return(
        <div className='photoPage'>
            {/* <MenuPagesHeader7/> */}
            <div className='photoContainer'>
                <div className='photoContainerHeader'>
                    <span>О Нас</span>
                </div>
                <div className='aboutText'>
                    <p> Малярия является одной из самых успешных компаний в области лакокрасочных, изоляционных и кровельных работ в Пермском крае и Тюменской области.</p>
                    <p>В составе нашей команды компетентные, профессиональные специалисты с большим опытом работ, наличием необходимых лицензий и допусков, в том числе для выполнения высотных работ.</p>
                    <p>Опыт работы как с частным, так и с государственным сектором позволил нашей компании выработать индивидуальный подход к каждому нашему заказчику.</p>
                    <p>В соответствующих разделах сайта  представлены примеры выполнения наших работ.</p>
                </div>

                <div className='photoContainerHeader'>
                    <span>Примеры наших работ</span>
                </div>
                <div className='photoContainerSlide' >
                    <div className="left">
                     <button onClick={prevSlide}><IconLeft/></button>
                    </div>
                    <div className='photoContainerSlideImg'>
                    {
                        shipArray[currenrIndex]== shipArray[0] ? 
                    <video controls className="photovideo1">
                         <source src={videoPath15} type="video/mp4" />
                     </video> :
                         <img src={shipArray[currenrIndex]}/>
                    }
                   
                    </div>
                    <div className="right">
                        <button onClick={nextSlide}><IconRight/></button>
                    </div>
                </div>
            </div>
        </div>
    )
}