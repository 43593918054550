import { useState } from 'react'

import { MenuPagesHeader } from '../../components/menuPagesHeader/menuPagesHeader'
import { IconLeft } from '../../utils/icons/left'
import { IconRight } from '../../utils/icons/right'

import img1 from "../../utils/img/lakokrasochniye/img1.jpg"
import img2 from "../../utils/img/lakokrasochniye/img2.jpg"
import img3 from "../../utils/img/lakokrasochniye/img3.jpg"
import img4 from "../../utils/img/antikarizovanniye/img4.jpg"
import img5 from "../../utils/img/antikarizovanniye/img5.jpg"

import './germetizacya.css'

export const Germetizacya = () =>{
    const videoPath14 = `${process.env.PUBLIC_URL}/videos/video14.mp4`
    const videoPath4 = `${process.env.PUBLIC_URL}/videos/video4.mp4`
    const videoPath5 = `${process.env.PUBLIC_URL}/videos/video5.mp4`
    const videoPath6 = `${process.env.PUBLIC_URL}/videos/video6.mp4`
    const videoPath7 = `${process.env.PUBLIC_URL}/videos/video7.mp4`
    const videoPath8 = `${process.env.PUBLIC_URL}/videos/video8.mp4`
    const [currenrIndex, setCurrentIndex] = useState(0)
    const shipArray = [img4,img5,img1,img2,img3]
        const nextSlide = () =>{
            currenrIndex === shipArray.length-1 ? setCurrentIndex(0) : setCurrentIndex(currenrIndex + 1)
        }
         const prevSlide = () =>{
            currenrIndex === 0 ? setCurrentIndex(shipArray.length-1) : setCurrentIndex(currenrIndex - 1)
        }
    return(
        <div>
           {/* <MenuPagesHeader/> */}
           <div className="germetizacyaContainer">
            <div className='germetizacyaHeader'>
                <span>Антикоррозионные и лакокрасочные работы</span>
            </div>
            <div className='germetizacyaText'>
                <p>Антикоррозионная защита заключается в нанесении на поверхность защищаемых конструкций слоёв защитных покрытий на основе органических и неорганических материалов, в частности, лакокрасочных материалов, металлов и сплавов.</p>
                <p>Защита стальных конструкций от коррозии при помощи лакокрасочных систем.</p>               
            </div>
            <div className='germetizacyaHeader'>
                <span>Примеры наших работ</span>
            </div>
                <div className='germetizacyaphotoContainer'>
                    <div className='germetizacyaphotoContainerSlide' >
                        <div className="germetizacyaleft">
                            <button onClick={prevSlide}><IconLeft/></button>
                        </div>
                        <div className='germetizacyaphotoContainerSlideImg'>
                            <img src={shipArray[currenrIndex]}/>
                        </div>
                        <div className="germetizacyaright">
                            <button onClick={nextSlide}><IconRight/></button>
                        </div>
                        </div>
                </div>
                <div className='germetizacyaText'>
                <div className='germetizacyavideovideo'>
                    <video controls className="germetizacyavideo1" style={{height:"50vh"}}>
                        <source src={videoPath14} type="video/mp4" />
                    </video>
                    <video controls className="germetizacyavideo1">
                        <source src={videoPath8} type="video/mp4" />
                    </video>
                    <video controls className="germetizacyavideo1">
                        <source src={videoPath4} type="video/mp4" />
                    </video>
                    <video controls className="germetizacyavideo1">
                        <source src={videoPath5} type="video/mp4" />
                    </video>
                    <video controls className="germetizacyavideo1">
                        <source src={videoPath6} type="video/mp4" />
                    </video>
                    <video controls className="germetizacyavideo1">
                        <source src={videoPath7} type="video/mp4" />
                    </video>
                </div>
            </div>
           </div>
        </div>
    )
}