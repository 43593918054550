import { Header } from '../../components/header/header'
import { Ships } from '../../components/ships/ships'

import './homepage.css'

export const Homepage = () =>{
    return(
        <div>
            {/* <Header/> */}
            <Ships/>
        </div>
    )
}