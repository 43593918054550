import { useEffect,useState } from 'react'
import { MenuPagesHeader6 } from '../../components/menuPagesHeader6/menuPagesHeader'

import { IconLeft } from '../../utils/icons/left'
import { IconRight } from '../../utils/icons/right'

import img1 from "../../utils/img/peskostruy/img1.jpg"
import img4 from "../../utils/img/peskostruy/img4.jpg"
import img5 from "../../utils/img/peskostruy/img5.jpg"
import img6 from "../../utils/img/peskostruy/img6.jpg"
import img7 from "../../utils/img/peskostruy/img7.jpg"
import img8 from "../../utils/img/peskostruy/img8.jpg"
import img9 from "../../utils/img/peskostruy/img9.jpg"

import './malyar.css'

export const Malyar = () =>{
    const videoPath1 = `${process.env.PUBLIC_URL}/videos/video.mp4`
    const videoPath2 = `${process.env.PUBLIC_URL}/videos/video2.mp4`

    const [currenrIndex, setCurrentIndex] = useState(0)
    const shipArray = [img1,img4,img5,img6,img7,img8,img9]
    const nextSlide = () =>{
        currenrIndex === shipArray.length-1 ? setCurrentIndex(0) : setCurrentIndex(currenrIndex + 1)
    }
        const prevSlide = () =>{
        currenrIndex === 0 ? setCurrentIndex(shipArray.length-1) : setCurrentIndex(currenrIndex - 1)
    }
    return(
        <div>
            {/* <MenuPagesHeader6/> */}
            <div className="malyarContainer">
                <div className='malyarHeader'>
                    <span>Пескоструйная очистка</span>
                </div>
                <div className='malyarText'>
                    <p> Пескоструйная очистка — это холодная обработка поверхности обычным кварцевым песком (или иным абразивным материалом), распыляемым потоком воздуха. Частицы абразива за счет энергии сжатого воздуха разгоняются, набирают кинетическую энергию, а затем бьются о поверхность изделия и уже за счет своей энергии воздействуют на обрабатываемую площадь. Краска или ржавчина откалываются и улетают вместе с абразивом.</p>
                </div>
                <div className='malyarHeader'>
                    <span>Примеры наших работ</span>
                </div>
                <div className='malyarphotoContainer'>
                    <div className='malyarphotoContainerSlide' >
                        <div className="malyarleft">
                            <button onClick={prevSlide}><IconLeft/></button>
                        </div>
                        <div className='malyarphotoContainerSlideImg'>
                            <img src={shipArray[currenrIndex]}/>
                        </div>
                        <div className="malyarright">
                            <button onClick={nextSlide}><IconRight/></button>
                        </div>
                        </div>
                </div>
              
                <div className='malyarvideovideo'>
                    <video controls className="malyarvideo1">
                        <source src={videoPath1} type="video/mp4" />
                    </video>
                    <video controls className="malyarvideo1">
                        <source src={videoPath2} type="video/mp4" />
                    </video>
                </div>
            </div>
        </div>
    )
}