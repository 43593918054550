import { Outlet } from 'react-router'
import { Navbar } from '../menu/menu'
import { Footer } from '../footer/footer'

export const Layout = () =>{
    return(
        <div>
              <div>
                 <Navbar/>
              </div>
              <div>
                <Outlet/>
              </div>
              <div>
                <Footer/>
              </div>
        </div>
    )
}