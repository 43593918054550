import { Route, Routes } from "react-router"
import { Homepage } from "../../pages/homepage/homepage"
import { Germetizacya } from "../../pages/germetizacya/germetizacya"
import { Shlifovka } from "../../pages/shlifovka/shlifovka"
import { Pokraska } from "../../pages/pokraska/pokraska"
import { Peskostruy } from "../../pages/peskostruy/peskostruy"
import { Malyar } from "../../pages/malyar/malyar"
import { Ekovata } from "../../pages/ekovata/ekovata"
import { Photo } from "../../pages/photo/photo"
import { Contact } from "../../pages/contact/contact"
import { Layout } from "../layout/layout"

export const AppRouter = () => {
    return(
        <Routes>
            <Route>
               <Route path="/" element={<Layout/>} >
                            <Route  index element={<Homepage/>}/>
                            <Route path="germetizacya" element={<Germetizacya/>} />
                            <Route path="shlifovka" element={<Shlifovka/>} />
                            <Route path="pokraska" element={<Pokraska/>} />
                            <Route path="peskostruy" element={<Peskostruy/>} />
                            <Route path="malyar" element={<Malyar/>} />
                            <Route path="ekovata" element={<Ekovata/>} />
                            <Route path="photo" element={<Photo/>} />
                            <Route path="contact" element={<Contact/>} />
               </Route>         
            </Route>
        </Routes>
    )
}