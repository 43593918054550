import './ships.css'
import ship1 from './../../utils/img/ship1.png'
import ship2 from './../../utils/img/ship2.png'
import ship3 from './../../utils/img/ship3.png'
import ship4 from './../../utils/img/ship4.png'
import ship5 from './../../utils/img/ship5.png'
import ship6 from './../../utils/img/ship6.png'
import { Link } from 'react-router-dom'
export const Ships = () => {
    return(
        <div className='shipsContainer'>
             {/* <h1 className='shipsH1'>Формы корпуса</h1> */}
                <div className='ships'>
                    <div className='shipsImg'><Link to='photo'> <div className='shipsText'><h1>О Нас</h1></div></Link><img src={ship1}/> </div>
                    <div className='shipsImg'><Link to='germetizacya'> <div className='shipsText shipsTextTarbervox'><h3>Антикоррозионные и лакокрасочные работы</h3></div></Link><img src={ship3}/> </div>
                    <div className='shipsImg'><Link to='pokraska'> <div className='shipsText'><h1>Кровельные работы</h1></div></Link><img src={ship2}/> </div>
                    <div className='shipsImg'><Link to='peskostruy'> <div className='shipsText'><h1>Изоляционные работы</h1></div></Link><img src={ship4}/> </div>
                    <div className='shipsImg'><Link to='malyar'> <div className='shipsText'><h1>Пескоструйная очистка</h1></div></Link><img src={ship5}/> </div>
                    <div className='shipsImg'><Link to='contact'><div className='shipsText'><h1>Контакты</h1></div></Link><img src={ship6}/> </div>
                </div>
        </div>

    )
}