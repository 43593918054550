import { Link } from 'react-router-dom'
import { IconBxlTelegram } from '../../utils/icons/telegram'
import { IconWhatsapp } from '../../utils/icons/whatsapp'
import './contact.css'

export const Contact = () =>{
    return(
        <div>
            <div className='contactContainer'>
                <div className='contactHeader'>
                    <span>Контакты</span>
                </div>
                <div className='contactText'>
                    <p>Вы можете связаться с нами по телефонам:</p>
                    <a href="tel:+7-922-075-74-44">+7 (922) 075-74-44</a>
                    <ul>
                        <Link to="https://wa.me/79225777444"><li><IconWhatsapp/></li></Link>
                        <Link to='https://t.me/+79225777444'><li><IconBxlTelegram/></li></Link>
                    </ul>
                    <p>Адрес: 614000 Пермский край город Пермь ул. Монастырская 14 офис 351</p>
                </div>
            </div>
        </div>
    )
}