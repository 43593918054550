import { useState } from 'react'

import { MenuPagesHeader3 } from '../../components/menuPagesHeader3/menuPagesHeader3'
import { IconLeft } from '../../utils/icons/left'
import { IconRight } from '../../utils/icons/right'

import img1 from "../../utils/img/lakokrasochniye/img1.jpg"
import img2 from "../../utils/img/lakokrasochniye/img2.jpg"
import img3 from "../../utils/img/lakokrasochniye/img3.jpg"

import './shlifovka.css'

export const Shlifovka = () =>{

    const videoPath4 = `${process.env.PUBLIC_URL}/videos/video4.mp4`
    const videoPath5 = `${process.env.PUBLIC_URL}/videos/video5.mp4`
    const videoPath6 = `${process.env.PUBLIC_URL}/videos/video6.mp4`
    const videoPath7 = `${process.env.PUBLIC_URL}/videos/video7.mp4`
    const videoPath8 = `${process.env.PUBLIC_URL}/videos/video8.mp4`
    
    const [currenrIndex, setCurrentIndex] = useState(0)
    const shipArray = [img1,img2,img3]
    const nextSlide = () =>{
        currenrIndex === shipArray.length-1 ? setCurrentIndex(0) : setCurrentIndex(currenrIndex + 1)
    }
    const prevSlide = () =>{
        currenrIndex === 0 ? setCurrentIndex(shipArray.length-1) : setCurrentIndex(currenrIndex - 1)
    }
    return(
        <div>
            {/* <MenuPagesHeader3/> */}
            <div className='shlifovkaContainer'>
                <div className='shlifovkaHeader'>
                    <span>Лакокрасочные работы</span>
                </div>
                <div className='shlifovkaphotoContainer'>
                    <div className='shlifovkaphotoContainerSlide' >
                        <div className="shlifovkaleft">
                            <button onClick={prevSlide}><IconLeft/></button>
                        </div>
                        <div className='shlifovkaphotoContainerSlideImg'>
                            <img src={shipArray[currenrIndex]}/>
                        </div>
                        <div className="shlifovkaright">
                            <button onClick={nextSlide}><IconRight/></button>
                        </div>
                        </div>
                </div>
                <div className='shlifovkaText'>
                    <p>Защита стальных конструкций от коррозии при помощи лакокрасочных систем.</p>
                </div>
                <div className='shlifovkaVideoContainer'>
                    <video controls className="shlifovkavideo">
                        <source src={videoPath4} type="video/mp4" />
                    </video>
                    <video controls className="shlifovkavideo">
                        <source src={videoPath5} type="video/mp4" />
                    </video>
                    <video controls className="shlifovkavideo">
                        <source src={videoPath6} type="video/mp4" />
                    </video>
                    <video controls className="shlifovkavideo">
                        <source src={videoPath7} type="video/mp4" />
                    </video>
                    <video controls className="shlifovkavideo">
                        <source src={videoPath8} type="video/mp4" />
                    </video>
                </div>
            </div>
        </div>
    )
}