// import { NavLink } from 'react-router-dom'

// import './menu.css'

// export const Menu = ()=>{
//     return(
//         <div className='Menu'>
//             <ul className='MenuUl'>
//                 <li> <NavLink to="/">Главная</NavLink></li>
//                 <li> <NavLink to='germetizacya'>Антикоррозионные работы</NavLink></li>
//                 <li><NavLink to='shlifovka'>Лакокрасочные работы</NavLink></li>
//                 <li><NavLink to='pokraska'>Кровельные работы</NavLink></li>
//                 <li><NavLink to='peskostruy'>Изоляционные работы</NavLink></li>
//                 <li><NavLink to='malyar'>Пескоструйная очистка</NavLink></li>
//                 {/* <li><NavLink to='ekovata'>О Нас</NavLink></li> */}
//                 <li><NavLink to='photo'>О Нас</NavLink></li>
//                 {/* <li><NavLink to='contact'>КОНТАКТЫ</NavLink></li> */}
//             </ul>
//         </div>
//     )
// }

import { NavLink } from 'react-router-dom'
import React, { useState } from "react";
import "./menu.css";
 export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="Navbar">
          <NavLink to="/" className='nav-logo-link'> <span className="nav-logo">Малярия</span> </NavLink>    

        <div className={`nav-items ${isOpen && "open"}`}>
                 <NavLink onClick={() => setIsOpen(!isOpen)} to="/">Главная</NavLink>
                 <NavLink onClick={() => setIsOpen(!isOpen)} to='photo'>О Нас</NavLink>
                 <NavLink onClick={() => setIsOpen(!isOpen)} to='germetizacya'>Антикоррозионные и лакокрасочные работы</NavLink>
                 {/* <NavLink onClick={() => setIsOpen(!isOpen)} to='shlifovka'>Лакокрасочные работы</NavLink> */}
                 <NavLink onClick={() => setIsOpen(!isOpen)} to='pokraska'>Кровельные работы</NavLink>
                 <NavLink onClick={() => setIsOpen(!isOpen)} to='peskostruy'>Изоляционные работы</NavLink>
                 <NavLink onClick={() => setIsOpen(!isOpen)} to='malyar'>Пескоструйная очистка</NavLink>
                 <NavLink onClick={() => setIsOpen(!isOpen)} to='contact'>Контакты</NavLink>

                 
      </div>
     
      <div
        className={`nav-toggle ${isOpen && "open"}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="bar"></div>
      </div>
    </div>
  );
};

