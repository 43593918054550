import { useState } from 'react'

import { MenuPagesHeader5 } from '../../components/menuPagesHeader5/menuPagesHeader5'
import { IconLeft } from '../../utils/icons/left'
import { IconRight } from '../../utils/icons/right'

import img1 from "../../utils/img/izolacia/img1.jpg"
import img2 from "../../utils/img/izolacia/img2.jpg"
import img3 from "../../utils/img/izolacia/img3.jpg"
import img4 from "../../utils/img/izolacia/img4.jpg"
import img5 from "../../utils/img/izolacia/img5.jpg"
import img6 from "../../utils/img/izolacia/img6.jpg"

import './peskostruy.css'

export const Peskostruy = () =>{
    const videoPath9 = `${process.env.PUBLIC_URL}/videos/video9.mp4`
    const [currenrIndex, setCurrentIndex] = useState(0)
    const shipArray = [img1,img2,img3,img4,img5,img6]
        const nextSlide = () =>{
            currenrIndex === shipArray.length-1 ? setCurrentIndex(0) : setCurrentIndex(currenrIndex + 1)
        }
         const prevSlide = () =>{
            currenrIndex === 0 ? setCurrentIndex(8) : setCurrentIndex(currenrIndex - 1)
        }
    return(
        <div>
            {/* <MenuPagesHeader5/> */}
            <div className='peskostruyContainer'>
                <div className='peskostruyHeader'>
                    <span>Изоляционные работы</span>
                </div>
                <div className='peskostruyText'>
                    <p>Изоляционные работы в строительстве - это процесс создания защитного слоя или барьера для предотвращения проникновения влаги, тепла, звука или других вредных факторов внутрь здания или сооружения. Они могут включать в себя установку тепло- и звукоизоляционных материалов, гидроизоляции, пароизоляции, уплотнительных материалов и т.д.</p>
                </div>
                <div className='peskostruyHeader'>
                    <span>Примеры наших работ</span>
                </div>
                <div className='peskostruyphotoContainer'>
                    <div className='peskostruyphotoContainerSlide' >
                        <div className="peskostruyleft">
                            <button onClick={prevSlide}><IconLeft/></button>
                        </div>
                        <div className='peskostruyphotoContainerSlideImg'>
                            <img src={shipArray[currenrIndex]}/>
                        </div>
                        <div className="peskostruyright">
                            <button onClick={nextSlide}><IconRight/></button>
                        </div>
                        </div>
                </div>
              
                <div className='peskostruyVideoContainer'>
                    <video controls  style={{width:"80%", height:"52vh"}}>
                        <source src={videoPath9} type="video/mp4" />
                    </video>
                </div>
        </div>
        </div>
    )
}